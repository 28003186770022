<template>
  <div class="py-2 container-fluid">
    <div class="kanban-container">
      <div class="kanban-board" v-for="board of boards" :key="board.id">
        <div :class="['card', 'card-principal', `board-${board.id}`]">
          <div class="card-header mb-0 px-2 py-2">
            <h4 class="card-title mb-0">
              <div class="d-flex justify-content-between">
                <span class="text-sm font-weight-bolder">
                  {{ board.name }}
                </span>
                <span class="text-sm">
                  ({{ board.items.length }})
                </span>
              </div>
              <div class="d-flex justify-content-start text-sm">
                {{ filters.currencyWithPrefix(board.total()) }}
              </div>
            </h4>
          </div>
          <div class="card-body p-3">
            <draggable group="cancelamentos" @change="log" :list="board.items" :move="onMoveCallback" @end="onEnd"
              @start="onStart" item-key="id" class="dragglable" :id="board.id">
              <template #item="{ element }">
                <cancelamento-card-kanban :cancelamento="element" :key="element.key" />
              </template>
            </draggable>
          </div>
        </div>
      </div>
    </div>
    <div id="new-board-modal" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="h5 modal-title">Choose your new Board Name</h5>
            <button type="button" class="btn close pe-1" data-dismiss="modal" data-target="#new-board-modal"
              aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="pt-4 modal-body">
            <div class="mb-4 input-group">
              <span class="input-group-text">
                <i class="far fa-edit"></i>
              </span>
              <input id="jkanban-new-board-name" class="form-control" placeholder="Board Name" type="text" />
            </div>
            <div class="text-end">
              <button id="jkanban-add-new-board" class="m-1 btn btn-primary" data-toggle="modal"
                data-target="#new-board-modal">
                Save changes
              </button>
              <button class="m-1 btn btn-secondary" data-dismiss="modal" data-target="#new-board-modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="new-board-modal-backdrop" class="fixed inset-0 z-40 hidden bg-black opacity-50"></div>
    <div id="jkanban-info-modal" class="modal fade" style="display: none" tabindex="-1" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="h5 modal-title">Task details</h5>
            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="pt-4 modal-body">
            <input id="jkanban-task-id" class="d-none" />
            <div class="mb-4 input-group">
              <span class="input-group-text">
                <i class="far fa-edit"></i>
              </span>
              <input id="jkanban-task-title" class="form-control" placeholder="Task Title" type="text" />
            </div>
            <div class="mb-4 input-group">
              <span class="input-group-text">
                <i class="fas fa-user"></i>
              </span>
              <input id="jkanban-task-assignee" class="form-control" placeholder="Task Assignee" type="text" />
            </div>
            <div class="form-group">
              <textarea id="jkanban-task-description" class="form-control" placeholder="Task Description"
                rows="4"></textarea>
            </div>
            <div class="alert alert-success d-none">Changes saved!</div>
            <div class="text-end">
              <button id="jkanban-update-task" class="m-1 btn btn-primary" data-toggle="modal"
                data-target="#jkanban-info-modal">
                Save changes
              </button>
              <button class="m-1 btn btn-secondary" data-dismiss="modal" data-target="#jkanban-info-modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="jkanban-info-modal-backdrop" class="fixed inset-0 z-40 hidden bg-black opacity-50"></div>
    <button type="button" :hidden="true" class="btn btn-primary" data-bs-toggle="modal" data-bs-backdrop="static"
      data-bs-target="#modal-cancelar-cliente" ref="showModalCancelarCliente">
    </button>
    <div id="modal-cancelar-cliente" class="modal fade" data-bs-backdrop="static" tabindex="-1"
      data-bs-keyboard="false">
      <div class="modal-dialog mt-lg-10 modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              Deseja realmente cancelar o cliente abaixo?
            </h5>
          </div>
          <div class="modal-body">
            <h4 class="text-center text-primary">
              {{ transitionElement?.element.cliente?.razaoSocial }}
            </h4>
            <hr>
            <div class="row">
              <div class="col-md-12 d-flex justify-content-end">
                <argon-switch v-model="churnOptions.utilizaraBaseConsulta" label-class="text-sm text-dark"
                  :input-class="churnOptions.utilizaraBaseConsulta ? `bg-success` : ''" :reverse="true">
                  <span class="text-bold me-2">
                    Cliente utilizará base para consulta
                  </span>
                </argon-switch>
              </div>
            </div>
            <hr>
            <div class="row" v-if="churnOptions.utilizaraBaseConsulta">
              <div class="col-md-6">
                <label>
                  Data limite para utilização da base
                </label>
                <input-date-time v-model="churnOptions.dataLimiteBaseConsulta" text-align="center" />
              </div>
              <div class="col-md-6">
                <label for="">
                  Valor cobrado
                </label>
                <input-currency v-model="churnOptions.valorBaseConsulta" class="form-control text-right" />
              </div>
            </div>
            <span class="text-dark text-xs">
              Ao realizado o cancelamento esse processo não poderá ser desfeito!.
              <br>
              Caso o cliente não utilize a base de consulta, a base sera desativada automaticamente.
              <br>
              Se optar por utilizar a base para consulta você deve informar a data limite e o valor a ser cobrado.
            </span>
          </div>
          <div class="modal-footer d-flex justify-content-between">
            <button type="button" class="btn bg-gradient-dark btn-sm" data-bs-dismiss="modal" @click="cancelUpdate()">
              Fechar
            </button>
            <button type="button" class="btn bg-gradient-danger btn-sm" @click="applyUpdate()">
              Confirmar cancelamento
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import api from '@/services/api'
import draggable from 'vuedraggable';
import CancelamentoCardKanban from '@/views/pages/Cancelamentos/components/CancelamentoCardKanban.vue';
import { errorToast, successToast } from '@/utils/alerts';
import ArgonSwitch from '@/components/ArgonSwitch.vue';
import InputCurrency from '@/components/InputCurrency.vue';
import InputDateTime from '@/components/InputDateTime.vue';
import moment from 'moment';

export default {
  name: "KanbanCancelamentos",
  components: {
    InputCurrency,
    InputDateTime,
    ArgonSwitch,
    draggable,
    CancelamentoCardKanban
  },
  data() {
    return {
      boardIds: {
        NOVAS: 0,
        EM_TRATATIVA: 1,
        AGUARDANDO_DISTRATO: 2,
        REVERTIDOS: 3,
        CANCELADOS: 4,
      },
      transitionElement: null,
      churnOptions: {
        utilizaraBaseConsulta: false,
        dataLimiteBaseConsulta: null,
        valorBaseConsulta: 0.0
      },
      boards: [
        {
          id: 0,
          name: 'Novas',
          items: [],
          total: () => this.totalBoard(0),
        },
        {
          id: 1,
          name: 'Em Tratativa',
          items: [],
          total: () => this.totalBoard(1),
        },
        {
          id: 2,
          name: 'Aguardando Distrato',
          items: [],
          total: () => this.totalBoard(2),
        },
        {
          id: 4,
          name: 'Cancelados',
          items: [],
          total: () => this.totalBoard(4),
        },
        {
          id: 3,
          name: 'Revertidos',
          items: [],
          total: () => this.totalBoard(3),
        },
      ],
      groupDestination: '',
      groupOrigin: '',
    }
  },
  mounted() {
    this.fetchData();
    moment.locale('pt-BR');
  },
  computed: {

  },
  methods: {
    fetchData() {
      api.get(`/cancelamentos/list`)
        .then(response => {
          const { data } = response;

          this.cancelamentos = data;

          this.setBoards();
        })
        .catch(e => console.log(e));
    },
    totalBoard(boardId) {
      const board = this.boards.find(board => board.id == boardId);

      return board.items.reduce((acc, item) => acc + (item.cliente?.valor ?? 0), 0);
    },
    setBoards() {
      const { boards, cancelamentos } = this;

      const items = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
      };

      for (const cancelamento of cancelamentos) {
        items[cancelamento.status ?? 0].push(cancelamento);
      }

      for (const key in items) {
        if (Object.hasOwn(items, key)) {
          const element = items[key];

          const board = boards.find(board => board.id == key);
          board.items = element;
        }
      }
    },
    cancelUpdate() {
      const { added } = this.transitionElement;

      this.revertChanges(added);
    },
    async applyUpdate() {
      const { element, groupDestination } = this.transitionElement;

      const { dataLimiteBaseConsulta, utilizaraBaseConsulta } = this.churnOptions;

      const today = moment().startOf('day');

      if (utilizaraBaseConsulta && moment(dataLimiteBaseConsulta).startOf('day').isBefore(today)) {
        errorToast('Data limite para utilização da base deve ser maior que a data atual');
        return;
      }

      if (this.transitionElement) {
        const response = await this.updateStatus(element, groupDestination);

        if (!response) {
          this.revertChanges(this.transitionElement.added);
          return;
        }

        this.$refs.showModalCancelarCliente.click();
      }
    },
    setDefaultChurnOptions() {
      this.churnOptions = {
        utilizaraBaseConsulta: false,
        dataLimiteBaseConsulta:
          moment()
            .startOf('day')
            .toDate(),
        valorBaseConsulta: 0.0
      };
    },
    log(event) {
      const { added } = event;

      if (added) {
        const { groupDestination } = this;

        const { element } = added;

        if (groupDestination == this.boardIds.CANCELADOS) {
          this.setDefaultChurnOptions();

          this.$refs.showModalCancelarCliente.click();

          this.transitionElement = {
            element,
            groupDestination,
            added
          };

          return;
        }

        this.updateStatus(element, groupDestination);
      }
    },
    revertChanges(added) {
      const { element } = added;

      const { groupDestination, groupOrigin } = this;

      const boardOrigin = this.boards.find(board => board.id == groupOrigin);
      boardOrigin.items.push(element);

      const boardDestination = this.boards.find(board => board.id == groupDestination);
      boardDestination.items = boardDestination.items.filter(item => item.id != element.id);
    },
    async updateStatus(element, status) {
      const { id } = element;

      try {
        const payload = status == this.boardIds.CANCELADOS ?
          { ...this.churnOptions } : {};

        await api.patch(`/cancelamentos/${id}/status/${status}`, payload);

        element.status = status;

        successToast('Status atualizado com sucesso');

        return true;
      } catch (error) {
        errorToast('Erro ao atualizar status');
        return false;
      }
    },
    onMoveCallback(event) {
      const { to, from } = event;

      this.groupDestination = to.id;
      this.groupOrigin = from.id;

      if (from.id == this.boardIds.CANCELADOS) {
        return false;
      }
    },
    onStart(event) { },
    onEnd(event) { },
  }
};
</script>
<style lang="scss" scoped>
.kanban-container {
  display: flex;
  gap: 1rem;
  padding-bottom: 1em;

  .kanban-board {
    width: 20%;
    min-width: 200px;
    border: 1.4px solid #fff;

    .card-principal {
      height: 100%;

      .card-header {
        background-color: #f8f9fe;
        border-bottom: 1px solid #e9ecef;
        padding: 0.78rem;
        border-radius: 0.75rem 0.75rem 0 0;
        color: #FFF;
      }

      .card-header .card-title {
        font-size: 1.2rem;
      }

      &.board-0 .card-header {
        background-color: #8392ab;
      }

      &.board-1 .card-header {
        background-color: #5e72e4;
      }

      &.board-2 .card-header {
        background-color: #FBD38D;
      }

      &.board-3 .card-header {
        background-color: #2dce89;
      }

      &.board-4 .card-header {
        background-color: #f5365c;
      }


      .card-title {
        color: #FFF;
      }

      .dragglable {
        height: 100%;
      }
    }


  }
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>
